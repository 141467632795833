import { Component, OnInit } from '@angular/core';
import { MMRGlobals } from 'src/app/core/services/mmr-globals';
import { BreadCrumbMenu } from '../breadcrumb-menu';

@Component({
  selector: 'app-about',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss'],
})
export class SurveyComponent implements OnInit {
  constructor(public globals: MMRGlobals) {}

  ngOnInit(): void {}
}
