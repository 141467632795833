import { Inject, Injectable } from '@angular/core';
import {
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  RedirectRequest,
  PopupRequest,
  AuthenticationResult,
  InteractionStatus,
  EventMessage,
  EventType,
} from '@azure/msal-browser';
import {
  registerMgtPeoplePickerComponent,
  registerMgtPersonComponent,
  registerMgtFileListComponent,
  registerMgtFileComponent,
} from '@microsoft/mgt-components';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { filter, Subject, takeUntil } from 'rxjs';
import { appConfiguration } from '../app.configuration';
import { TemplateHelper } from '@microsoft/mgt';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  loginDisplay = false;
  isIframe = false;
  public readonly _destroying$ = new Subject<void>();
  constructor(
    @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration,
    public authService: MsalService,
    public msalBroadcastService: MsalBroadcastService
  ) {}

  init() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        this.setProviderForMSGraphToolkit();
      });
  }
  setProviderForMSGraphToolkit() {
    Providers.globalProvider = new Msal2Provider({
      clientId: appConfiguration.clientId,
      authority: appConfiguration.authority,
    });
    TemplateHelper.setBindingSyntax('[[', ']]');
    registerMgtPersonComponent();
    registerMgtFileListComponent();
    registerMgtFileComponent();
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }
  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }
  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }
  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
  onDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
